/* src/index.css */
@font-face {
    font-family: 'MyFont';
    src: url('./assets/my-font.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
body {
    margin: 0;
    padding: 0;
    font-family: 'MyFont', sans-serif;

    background: url('./assets/bg.png') no-repeat center center fixed;
    background-size: cover;
  }
  
  #root {
    height: 100%;
  }
  